import React from 'react';
import classes from './PublicHeader.module.css';
import Logo from '../../../assets/image/logo.png';

const PublicHeader = () => {
  return (
    <div className={classes.header}>
      <img src={Logo} alt='Logo' className={classes.logo} />
    </div>
  );
};

export default PublicHeader;
