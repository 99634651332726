import React, { useEffect, useState, useRef  } from 'react';

import { postData } from '../services/api';
import { BOOKING_PAGE } from '../utils/constants';
import { retrieveFromLocalStorage } from '../helpers/localStorageHelper';
import classes from './PaymentSuccess.module.css';

import CompanyLogo from '../assets/image/logo.png';
import LoadingIcon from '../assets/image/Skateboarding.gif';

const PaymentSuccess = () => {
  const isDataFetched = useRef(false);
  const [paymentStatus, setPaymentStatus] = useState(null); 
  const [referralCode, setReferralCode] = useState(null); 
  const [customer, setCustomer] = useState(''); 
  const [isLoading, setIsLoading] = useState(true);
  const [countdown, setCountdown] = useState(10);

  const userDetails = retrieveFromLocalStorage("userDetails");
  const paymentDetails = retrieveFromLocalStorage("paymentDetails");
  
  const userData = {
    'payment_id': paymentDetails?.paymentId,
    'response_tid': paymentDetails?.response_tid,
    'endpoint_type': 'public'
  };

  const fetchData = async () => {
    setIsLoading(true); 

    try {
      const response = await postData('customer/payment/update', userData);
       
      setCustomer(response?.data?.customer)
      setReferralCode(response?.data?.refererral_code)
      setPaymentStatus(response.code);

      localStorage.removeItem('userDetails');
      localStorage.removeItem('paymentDetails');
    } catch (error) {
      setPaymentStatus(500);
    } finally {
      setIsLoading(false); 
    }
  };

  useEffect(() => {
    if (!isDataFetched.current) {
      fetchData();
      isDataFetched.current = true;
    }
  }, []);

  useEffect(() => {
    if (paymentStatus === 200) {
      const interval = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);

      const timer = setTimeout(() => {
          window.location.href = BOOKING_PAGE + '/?code=' + encodeURIComponent(referralCode);
      }, 10000);

      return () => {
        clearInterval(interval);
        clearTimeout(timer);
      };
    }
  }, [paymentStatus]);
  
  return (
    <>
      {isLoading ? (
        <>
          <img src={LoadingIcon} alt="Loading" className={classes.loadingIcon} />
          <h1 className={classes.description}>Processing...</h1>
        </>
      ) : (
        <div className={classes.container}>
          <div className={classes.content}>
            <img src={CompanyLogo} alt="Company Logo" className={classes.logo} />
            {paymentStatus === 200 ? (
              <>
                <h1 className={classes.title}>Payment Success!</h1>
                <p className={classes.description}>Hi, <span style={{ textTransform: 'capitalize' }}>{customer}</span></p>
                <p className={classes.description}>Thank you for registering with Travel Master Club.</p>
                <p className={classes.description}>Please check your email for account activation instructions.</p>
                <p className={classes.countdown}>Redirecting in {countdown} seconds...</p>
              </>
            ) : paymentStatus !== 200 ? (
              <>
                <h1 className={classes['error-title']}>Payment Error</h1>
                <p className={classes['error-description']}>Oops! An error occurred while processing your payment.</p>
              </>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentSuccess;
