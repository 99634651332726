import React, { useState, useEffect,useRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PublicHeader from '../components/layout/Header/PublicHeader';
import PublicFooter from '../components/layout/Footer/PublicFooter';
// import UserContext from '../contexts/UserContext';
import { postData } from '../services/api';
import { saveToLocalStorage } from '../helpers/localStorageHelper';
import classes from './Homepage.module.css';

import founder1 from '../assets/image/founder1.png'; 
import founder2 from '../assets/image/founder2.png';


const TestimonialModal = ({ testimonial, onClose }) => {
  if (!testimonial) return null;

  return (
    <div className={classes.modal}>
      <div className={classes.modalContent}>
        <img 
          src={testimonial.image} 
          alt={testimonial.name}
          className={classes.modalImage} />
        <h3>{testimonial.name}</h3>
        <h4>{testimonial.title}</h4>
        <h5>{testimonial.position}</h5>
        <p>{testimonial.quote}</p>
        <p>{testimonial.quote1}</p>
        <p>{testimonial.quote2}</p>
        <p>{testimonial.quote3}</p>

        <button onClick={onClose} className={classes.closeButton}>Close</button>
      </div>
    </div>
  );
};


const testimonials = [
  {
    name: "MARK JONES N. DELA CRUZ",
    quote: "Mark Jones N. Dela Cruz, the driving force behind Experience More Travel Specialists Inc., assumes the pivotal role of President and CEO. His journey commenced in the dynamic realm of the Life Insurance Industry, where diligence and disciplined work ethic propelled him to the forefront as a top Financial Planner. Mark's trajectory soared further as he transitioned into politics, earning distinction as an astute Barangay Councilor in Cebu—a position he continues to hold with honor.",
    quote1: "Fueled by an insatiable passion for travel, he founded the travel agency, Experience More Travel Specialists Inc. Under his adept leadership, the agency has flourished, earning acclaim for excellence in the travel industry. His expertise extends beyond conventional boundaries, encompassing e-commerce, travel distribution, and executive coaching, positioning him as a trusted advisor for local government units in Cebu and Bohol.",    
    quote2: "His visionary approach is evident in the expansion of business horizons with Star Cruizes Enterprise, a subsidiary specializing in government contracts. This venture stands as a testament to his commitment to community welfare, providing essential supplies and services such as office and IT equipment, all types of sports uniforms, medical and emergency response gears, CCTV systems, and more.",    
    quote3: "A leader with a profound dedication to public service, his entrepreneurial pursuits are guided by a commitment to serving local government units, ensuring seamless access to quality items and services. Mark Jones N. Dela Cruz transcends the conventional role of a leader; he is a visionary entrepreneur shaping a lasting legacy on the industry while remaining a devoted public servant at heart.",    
    title: "The Founder",
    position: "Visionary Entrepreneur and Esteemed CEO",
    image: founder2
  },
  {
    name: "RAQUEL 'Aking' RUIZ",
    quote: "Raquel Milagros 'Aking' Ruiz, brings a wealth of expertise as a certified US Nurse currently based in Los Angeles, California, USA. Her strategic location places her at the forefront of the latest trends in the travel industry and ensures a nuanced understanding of the cutting-edge technological advantages shaping our dynamic landscape.",
    quote1: "In her role as COO, her responsibilities encompass finance and distribution, international procurement, travel distribution, and customer service, reflecting a comprehensive skill set tailored for success. With a track record in Public Service, Travel, Finance, and Distribution, she seamlessly integrates online platforms to keep Experience More Travel Specialists Inc. competitive in multi-location operations and product development.",    
    quote2: "Her visionary approach is underscored by her belief that <blockquote>If your business is not on the Internet, you are not doing business at all,</blockquote> showcasing her commitment to staying at the forefront of industry trends. As a dynamic entrepreneur and compassionate public servant, Aking's dedication extends beyond borders. Currently located in the vibrant hub of Los Angeles, she not only navigates the complexities of the travel industry but also ensures that local government units have easy access to quality items and services.",    
    quote3: "In the tapestry of Experience More Travel Specialists Inc., Raquel Milagros 'Aking' Ruiz stands as more than a COO; she is a visionary leader shaping a future where excellence, compassion, and a global perspective converge, leaving an indelible mark on the industry and communities we serve.",    
    title: "The Founder",
    position: "Chief Operation Officer",
    image: founder1
  }
 

];

const Homepage = () => {
  const [current, setCurrent] = useState(0);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');  
  const [shakeName, setShakeName] = useState(false);
  const [shakeEmail, setShakeEmail] = useState(false);
  const nameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);
  

  const videoId = 'xsUXh7IUGcM'; 

  const openModal = (testimonial) => {
    setSelectedTestimonial(testimonial);
    setIsModalOpen(true);
  };

  const showWarning = (message) => {
    toast.warn(message);
  };
  
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
    if (shakeName) setShakeName(false);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    if (shakeEmail) setShakeEmail(false);
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async () => {
    let valid = true;
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (!code) {
      showWarning('Invalid Referral Link!');
      return;
    }

    if (!name) {
      setShakeName(true);
      nameInputRef.current.focus();
      valid = false;
    }

    if (valid && (!email || !validateEmail(email))) {
      setShakeEmail(true);
      emailInputRef.current.focus();
      valid = false;
    }

    if (valid) {
      setIsLoading(true); // Start loading

      const data = {
        'code': code,
        'endpoint_type': 'public',
        'name': name,
        'email': email
      };

      try {
        const response = await postData('sales/landing', data);
        
        if(response.code === 200){
          data['transaction_id'] = response.data.TransactionID; 
          saveToLocalStorage("userDetails", data);
          navigate('/offer');        
        }

      } catch (error) {
        console.error("error on submit:", error);
      } finally {
        setIsLoading(false); 
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent((current) => (current + 1) % testimonials.length); 
    }, 1000); 
    return () => clearInterval(interval); 
  }, []);

  return (
        <div className={classes.container}>
          <PublicHeader />        
          <div className={classes['video-section']}>
            <div className={classes['iframe-container']}>
              <iframe
                src={`https://www.youtube.com/embed/${videoId}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <h1>Best FREE Travel Opportunity Ever!</h1>
            <h4>Gusto mo ba Malaman PAANO MAKA LIBRE NG BAKASYON AT TRAVEL?</h4>
            <h3>SIGN UP THE FORM BELOW and we will GIVE YOU MORE DETAILS!</h3>
          </div>  
          <div className={classes['signup-section']}>
            <div className={classes['more-info-box']}>
              <h1>Sign up below to learn the FULL DETAILS of FREE TRAVEL!</h1>
              <h5>Enter your details so we can SEND YOU MORE INFO to your EMAIL ADDRESS. (Please be sure to check your email including SPAM)</h5>
              <input 
                type="text" 
                placeholder="Enter name" 
                value={name} 
                onChange={handleNameChange} 
                onKeyPress={handleKeyPress}
                className={shakeName ? classes['shake-animation'] : ''} 
                ref={nameInputRef}                
                required 
              />
              <input 
                type="text" 
                placeholder="Enter email" 
                value={email} 
                onChange={handleEmailChange} 
                onKeyPress={handleKeyPress}
                className={shakeEmail ? classes['shake-animation'] : ''} 
                ref={emailInputRef}                
                required 
              />
              <button onClick={handleSubmit} disabled={isLoading} className={classes.submitButton}>
                  {isLoading ? (
                      <>
                          <div className={classes.spinner}></div>
                          <span className={classes.loadingText}>Processing...</span>
                      </>
                  ) : 'MORE INFO NOW'}
              </button>              
            </div>    
          </div>
          <div className={classes['steps-section']}>
            <div>
              <h2>Why Choose Our Travel Club?</h2>
              <p>1. Experience More Travel, the company behind this membership club is one of the most trusted names in Travel here in Cebu catering to Local and International Travels.</p>
              <p>2. Dynamic and well experienced Membership Team to help the ordinary citizen experience more travel and experience more life!</p>
              <p>3. State of the Art system to monitor your membership growth and earned bonuses.</p>
              <p>4. Leadership & Character Development Trainings</p>
              <p>5. People oriented, passionate leadership team.</p>
            </div>
            <div>
              <h2>Here's what you can look forward to:</h2>
              <p>1. Free travel opportunities across the globe</p>
              <p>2. Exclusive discounts on accommodations</p>
              <p>3. Curated itineraries and personalized recommendations</p>
              <p>4. Lucrative Rewards for expanding our membership</p>
              <p>5. Join a vibrant travel community</p>
            </div>
          </div>
          <div className={classes['comment-section']}>       
            <div className={classes.testimonial}>
              {testimonials.map((testimonial, index) => (
                <div key={index} className={classes.testimonialItem}>
                  <img src={testimonial.image} alt={testimonial.name} className={classes.testimonialImage} />
                  <div>
                    <h3 className={classes.testimonialName}>{testimonial.name}</h3>
                    <h4 className={classes.testimonialTitle}>{testimonial.title}</h4>
                    <h5 className={classes.testimonialPosition}>{testimonial.position}</h5>
                    <p className={classes.testimonialQuote}>
                      "{testimonial.quote}" 
                      <a href="#!" onClick={() => openModal(testimonial)} className={classes.moreInfoLink}>More Info</a>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <PublicFooter />      
          <ToastContainer position={toast.POSITION.TOP_CENTER} />

          {isModalOpen && 
            <TestimonialModal 
              testimonial={selectedTestimonial} 
              onClose={() => setIsModalOpen(false)} 
            />
          }          
        </div>
  );
};

export default Homepage;
